"use client";
import ErrorMessage from "@/components/shared/ErrorMessage";
import { useEffect } from "react";

type Props = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function Error({ error, reset }: Props) {
  useEffect(() => {
    console.log("error of this page: ", error);
  }, [error]);

  return (
    <ErrorMessage message={"هناك عطل في هذه الصفحة ونعمل حاليا علي إصلاحه"} />
  );
}
